import './AboutContentStyles.css'
import React from 'react'
import { Link } from 'react-router-dom'

const AboutContent = () => {
  return (
    <div className="about">
          <div className="left">
              <h1>Who Am I?</h1>
              <p>I am a photographer based out of Boston, Ma. My passion is to capture authentic memories that you will cherish forever! With nearly a decade of experience, photography has always been a passion of mine and I strive to create the best outcome for my clients. I am available for all variety of shoots including portraits, engagment, professional shoots, boudoir, and beyond. Please contact me for booking info and rates.</p>
              <Link to="/contact">
                  <button className="btn">Contact</button>
              </Link>
          </div>
          <div className="right">
              <div className="img-container">
                  <div className="img-stack top">
                      <img src='https://live.staticflickr.com/65535/52327979603_91f847941f_z.jpg' alt="1" className='img'/>
                  </div>
                  <div className="img-stack bottom">
                      <img src='https://live.staticflickr.com/65535/52327736976_7fa6780a9b_z.jpg' alt="1" className='img'/>
                  </div>
              </div>
          </div>
    </div>
  )
}

export default AboutContent