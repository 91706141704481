const ProjectData = [
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328035944_7fd4fd76dc_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327740206_d97b05a30e_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328162130_f610a8e2bb_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327740141_7a85a4734b_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327983018_511af7c63a_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328035534_ef70669000_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328161255_7ccfffe61d_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328035369_c6c8ab15b8_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328162095_78cff5f755_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52326785372_558fa906e3_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328160995_04e7de65b9_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327739481_b8b97b3356_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328036264_ecec7b0946_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328034944_5dc1e7a681_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328034834_0fc8039eb7_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327739226_4a69ab6c45_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328036169_4afb6d3100_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52326786222_c4136e0a91_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327982108_6757abc629_k.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328034714_d333f72ffe_k.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328160475_5a03f8e069_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328160300_f7c64d09d5_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52326786182_34c4872264_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328034309_18a3582556_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52326786077_9af4dd2993_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327981583_07419f02c9_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327981498_1fb9f6c35a_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52326783942_eba13c8ed3_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328033899_f7987843e3_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328159595_8a1122fd89_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52326783472_4ef5b73d81_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327737971_d8374641f4_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52326783252_585482b5d6_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328033239_b31db61803_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327980458_c8b0c286cc_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52328033144_055c09534a_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327980213_38a91ae869_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327980028_638961902b_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52326786067_1e3526cb9c_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52326786067_1e3526cb9c_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327983428_afcefe1dc3_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52326786042_1475b765dc_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
    {
        imgsrc: 'https://live.staticflickr.com/65535/52327983368_f16eb9b906_z.jpg',
        title: 'This is a title',
        text: 'This is a paragraph of text',
        view: 'https://blahblah.com'
    },
]

export default ProjectData