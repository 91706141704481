import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Work from '../components/Work'

const Gallery = () => {
  return (
    <div>
      <Navbar />
      {/* <HeroImg2 heading="Gallery" text="Most recent work" /> */}
      <Work />
      <Footer />
  </div>
  )
}

export default Gallery