import './FooterStyles.css'
import React from 'react'
import { FaInstagram, FaTiktok } from 'react-icons/fa'

const Footer = () => {
  return (
      <div className="footer">
        <div className="footer-container">
          <h4>Check out my social media for even more content:</h4>
                <div className="social">
                  <a href="https://www.instagram.com/paulinatakespix/" target="_blank" rel="noreferrer"><FaInstagram size={30} style={{ color: '#fff', marginRight: '2rem' }} /></a>
                  <a href="https://www.tiktok.com/@paulinatakespix" target="_blank" rel="noreferrer"><FaTiktok size={30} style={{ color: '#fff', marginRight: '2rem' }} /></a>
                </div>
        <p>Website by <a href="https://matthewsiperko.github.io/">Matthew Siperko</a> &copy; 2022</p>
        </div>
      </div>
  )
}

export default Footer
                  
