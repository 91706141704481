import './FormStyles.css'
import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import FormMessage from './FormMessage'

const Form = () => {
  const form = useRef();
  const messageRef = useRef(null)
  const [messageText, setMessageText] = useState('')
  const [messageType, setMessageType] = useState('')


  const successMessage = () => {
    setMessageText("Message sent successfuly!")
    setMessageType('success')
    messageRef.current.show()
  }

  const failureMessage = () => {
    setMessageText("message failed to send")
    setMessageType('failure')
    messageRef.current.show()
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qfu6bqg', 'template_pp3k1g9', form.current, 'vs2nhLMubb52bTt8N')
      .then(() => {
        successMessage()
        e.target.reset()
      }, () => {
        failureMessage()
      });
    
  };

  return (
    <>
      <FormMessage
        ref={messageRef}
        message={messageText}
        type={messageType}
      />
      <div className="form">
          <form ref={form} onSubmit={sendEmail}>
              <label>Your Name</label>
              <input type="text" name="user_name" placeholder='Type your name..' />
              <label>Email</label>
              <input type="email" name="user_email" placeholder='Type your email..' />
              <label>Message</label>
              <textarea rows="6" name="message" placeholder="Tell me what you're interested in.." ></textarea>
              <input type="submit" value="Send" className="btn" />
          </form>
      </div>
      </>
  )
}




export default Form