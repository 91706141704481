import React, {useState, forwardRef, useImperativeHandle} from 'react'
import './FormMessageStyles.css'

const FormMessage = forwardRef((props, ref) => {

    const [showMessage, setShowMessage] = useState(false)

    useImperativeHandle(ref, () => ({
        show() {
            setShowMessage(true)
            setTimeout(() => {
                setShowMessage(false)
            }, 3000)
        }
    }))

  return (
      <div
          className='message-container'
          id={showMessage ? "show" : "hide"}
          style={{ backgroundColor: props.type === "success" ? "#00F593" : "#ff0033" }}>
          <div className="symbol">
              {props.type === "success" ? <h1>&#x2713;</h1> : <h1>&#x2613;</h1>}
          </div>
          <div className="message">
             <p>{props.message}</p>
          </div>
    </div>
  )
})

export default FormMessage