import './HeroImgStyles.css'
import React from 'react'
import { Link } from 'react-router-dom'

const HeroImg = () => {
  return (
      <div className="hero">
          <div className="mask">
              <img src="https://live.staticflickr.com/65535/52328027469_336267bd4f_b.jpg" alt="Chess player" className="intro-img" />
          </div>
          <div className="content">
              <p>Hi, I'm Paulina McGrath</p>
              <h1>Photographer</h1>
          <div>
              <Link className='btn' to="/gallery">Gallery</Link>
              <Link className='btn btn-light' to="/contact">Contact</Link>
          </div>
          </div>
      </div>
  )
}

export default HeroImg